import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  Accordion as Acc,
  AccordionSummary as AccSum,
  AccordionDetails,
  Button,
  Box,
  styled,
  Grid,
  Divider,
  Typography,
  useTheme,
  useMediaQuery,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import { useScrollToTop } from "../../hooks/useScrollToTop";
import { useFormContext } from "react-hook-form";
import { InputField } from "../../components/InputField";
import { FileService } from "../../services/FileService";
import { ExpandMore } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { DocumentButton } from "./DocumentButton";
import {
  getFile,
  RegisterPrerequisites,
  setPrerrequisites,
} from "../../store/actions";
import { emailPattern } from "../../utils";
import { FileFlowService } from "../../services/FileFlowService";
import { Alert } from "../../components/Alert";
import { CustomModalAlert } from "../../components/CustomModalAlert";

const RootStyle = styled(Box)(({ theme }) => ({
  margin: "0 auto",
  width: "100%",
  maxWidth: "95%",
  transition: "height 500ms ease",
}));

const Accordion = styled(Acc)(({ theme }) => ({
  "&.MuiAccordion-root": {
    boxShadow: "none !important",
  },
  "&::before": {
    display: "none",
  },
}));

const Tip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[5],
    fontSize: 13,
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: "transparent",
    color: "#fff",
  },
}));

const AccordionSummary = styled(AccSum)(({ theme }) => ({
  borderBottom: "1px solid red",
  borderBottomColor: theme.palette.secondary.main40,
}));

export const ConfigReqPreviosTab = (props) => {
  const folioIdSolicitud = sessionStorage.getItem("FileId");
  const { control, handleSubmit, watch, setValue, formState, resetField } =
    useFormContext();
  const [searchUser, setsearchUser] = useState();
  const [alertCancel, setAlertCancel] = useState(false);
  const [searchIntegrante, setsearchIntegrante] = useState();
  const [loadingParticipant, setloadingParticipant] = useState(false);
  const [loadingIntegrante, setloadingIntegrante] = useState(false);
  const [results, setresults] = useState([]);
  const [resultsIntegrante, setresultsIntegrante] = useState([]);
  const [open, setOpen] = useState(false);
  const [openIntegrante, setOpenIntegrante] = useState(false);
  const [listDocuments, setListDocuments] = useState([]);

  const [editPart, setEditPart] = useState(false);
  const [editInte, setEditInte] = useState(false);

  const fileLoader = useRef(null);

  const watchParticipant = watch("searchParticipant");
  const watchParticipantName = watch("participantName");
  const watchIntegranteName = watch("integranteName");
  const watchSearchIntegrante = watch("searchIntegrante");
  const watchDocumentName = watch("documentName");
  const watchDocumentoObligatorio = watch("documentoObligatorio");
  const watchRequiereProcAprobacion = watch("requiereProcAprobacion");
  const watchLimitDateReqPrevios = watch("limitDateReqPrevios");

  const newFileState = useSelector((state) => state.NewFile);
  const fileEdit = useSelector((state) => state.File);
  const dataPre = useSelector((state) => state.File?.item?.Prerequisites);
  // const listSigners = useSelector((state) => state.NewFile.participants.sign);
  const dispatch = useDispatch();
  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.down("sm"));
  useScrollToTop();

  const getParticipantFromApi = async (search = "") => {
    const fileService = FileService.getInstance();
    setloadingParticipant(true);
    try {
      const response = await fileService.getParticipants({
        SearchText: search,
      });
      setresults(
        response?.Body?.map(({ ...rest }) => ({
          name: rest.Email,
          ...rest,
        }))
      );
    } catch (err) {
      console.log(err);
    } finally {
      setloadingParticipant(false);
    }
  };

  const getIntegranteFromApi = async (search = "") => {
    const fileService = FileService.getInstance();
    setloadingIntegrante(true);
    try {
      const response = await fileService.getParticipants({
        SearchText: search,
      });
      setresultsIntegrante(
        response.Body.map(({ ...rest }) => ({
          name: rest.Email,
          ...rest,
        }))
      );
    } catch (err) {
      console.log(err);
    } finally {
      setloadingIntegrante(false);
    }
  };

  useEffect(() => {
    if (editPart && results.length > 0) {
      console.log(results[0]);
      console.log("ENTRA AQUI MOOD EDICION");
      setValue("searchParticipant", results[0]);
      setValue(
        "participantName",
        `${results[0].Name ?? ""} ${results[0].FatherLastName ?? ""} ${
          results[0].MotherLastName ?? ""
        }`
      );
      setEditPart(false);
    }
  }, [results, editPart]);

  console.log(watchParticipant);

  useEffect(() => {
    if (editInte && resultsIntegrante.length > 0) {
      setValue("searchIntegrante", resultsIntegrante[0]);
      setValue(
        "integranteName",
        `${resultsIntegrante[0].Name ?? ""} ${
          resultsIntegrante[0].FatherLastName ?? ""
        } ${resultsIntegrante[0].MotherLastName ?? ""}`
      );
      setEditInte(false);
    }
  }, [resultsIntegrante, editInte]);

  console.log("EDICIONES", editInte, editPart);

  useEffect(() => {
    setValue("documentoObligatorio", true);
    if (newFileState?.editStack || folioIdSolicitud) {
      if (fileEdit.item) {
        setsearchUser(
          fileEdit?.item?.Prerequisites.UploadPrerequisitesUserEmail
        );
        setsearchIntegrante(
          fileEdit?.item?.Prerequisites?.PrerequisiteApprovalUserEmail
        );

        setListDocuments(
          fileEdit?.item?.Prerequisites?.PrerequisitesDocumentList === null
            ? []
            : fileEdit?.item?.Prerequisites?.PrerequisitesDocumentList
        );
        setValue(
          "requiereProcAprobacion",
          fileEdit?.item?.Prerequisites?.ActivePrerequisiteApproval
        );
        setValue(
          "limitDateReqPrevios",
          new Date(fileEdit?.item?.Prerequisites?.UploadPrerequisitesDeadline)
        );
        if (
          fileEdit?.item?.Prerequisites.UploadPrerequisitesUserEmail &&
          fileEdit?.item?.Prerequisites?.ActivePrerequisites === true
        ) {
          setEditPart(true);
          setEditInte(true);
        }
      }
    }
  }, [fileEdit.item]);

  const resetForm = useCallback(() => {
    setValue("searchParticipant", undefined);
    setValue("searchIntegrante", undefined);
  }, [setValue]);

  useEffect(() => {
    if (searchUser) {
      getParticipantFromApi(searchUser);
    }
  }, [searchUser]);

  useEffect(() => {
    if (searchIntegrante) {
      getIntegranteFromApi(searchIntegrante);
    }
  }, [searchIntegrante]);

  useEffect(() => {
    if (open) {
      setresults([]);
    }
  }, [open]);

  useEffect(() => {
    if (openIntegrante) {
      setresultsIntegrante([]);
    }
  }, [openIntegrante]);

  useEffect(() => {
    if (editPart) {
      // if (watchParticipant) {
      //   console.log("watchParticipant.:", watchParticipant);
      //   if (watchParticipant.Id) {
      //     console.log("PUNTO UNO");
      //     setValue(
      //       "participantName",
      //       `${watchParticipant.Name ?? ""} ${
      //         watchParticipant.FatherLastName ?? ""
      //       } ${watchParticipant.MotherLastName ?? ""}`
      //     );
      //   } else {
      //     setValue("participantName", "");
      //   }
      // }
      console.log("NUEVOEXPE");
    } else {
      if (watchParticipant) {
        if (watchParticipant.Id) {
          setValue(
            "participantName",
            `${watchParticipant.Name ?? ""} ${
              watchParticipant.FatherLastName ?? ""
            } ${watchParticipant.MotherLastName ?? ""}`
          );
        } else {
          setValue("participantName", "");
        }
      }
    }
  }, [watchParticipant, setValue]);

  console.log("watchParticipant", watchParticipant);

  // useEffect(() => {
  //   if (!watchParticipant) {
  //     console.log("resetForm();: ",watchParticipant)
  //     resetForm();
  //   }
  // }, [watchParticipant, setValue, resetForm]);

  useEffect(() => {
    if (editInte) {
      // if (watchSearchIntegrante) {
      //   if (watchSearchIntegrante?.Id) {
      //     setValue(
      //       "integranteName",
      //       `${watchSearchIntegrante?.Name ?? ""} ${
      //         watchSearchIntegrante?.FatherLastName ?? ""
      //       } ${watchSearchIntegrante?.MotherLastName ?? ""}`
      //     );
      //   } else {
      //     setValue("integranteName", "");
      //   }
      // }
    } else {
      if (watchSearchIntegrante) {
        if (watchSearchIntegrante?.Id) {
          console.log("TOMA AQUI");
          setValue(
            "integranteName",
            `${watchSearchIntegrante?.Name ?? ""} ${
              watchSearchIntegrante?.FatherLastName ?? ""
            } ${watchSearchIntegrante?.MotherLastName ?? ""}`
          );
        } else {
          setValue("integranteName", "");
        }
      }
    }
  }, [watchSearchIntegrante, setValue]);

  // useEffect(() => {
  //   if (!watchSearchIntegrante) {
  //     console.log("resetForm();: ",watchSearchIntegrante)
  //     resetForm();
  //   }
  // }, [watchSearchIntegrante, setValue, resetForm]);

  useEffect(() => {
    // console.log("watchDocumentName: ", watchDocumentName)
  }, [watchDocumentName, setValue, resetForm]);

  useEffect(() => {
    // console.log("watchDocumentoObligatorio: ", watchDocumentoObligatorio)
  }, [watchDocumentoObligatorio, setValue, resetForm]);

  useEffect(() => {
    // console.log("watchRequiereProcAprobacion: ", watchRequiereProcAprobacion)
    // setValue("searchIntegrante", undefined);

    if (
      !fileEdit.item ||
      fileEdit.item === undefined ||
      fileEdit.item === null
    ) {
      setValue("limitDateReqPrevios", null);
    }
  }, [resetForm]);

  useEffect(() => {
    // console.log("watchLimitDateReqPrevios: ", watchLimitDateReqPrevios);
  }, [watchLimitDateReqPrevios, setValue, resetForm]);

  // useEffect(() => {
  //   console.log("regPrereqData: ", regPrereqData);
  //   if (
  //     regPrereqData.items !== undefined &&
  //     regPrereqData.items !== null &&
  //     changeDatos
  //   ) {
  //     console.log('AVANZA')
  //     setChangeDatos(false);
  //     props.handleNextStep();
  //   }
  // }, [regPrereqData, setValue, resetForm]);

  let getFileLoaded = (event) => {
    // console.log("getFileLoaded: event.target.files  ", event.target.files);
    // console.log("getFileLoaded:  event.target ", event.target);
    if (event.target.files && event.target.files[0]) {
      // console.log(
      //   "getFileLoaded: event.target.files[0].name , watchDocumentName ",
      //   event.target.files[0].name,
      //   watchDocumentName
      // );
      var nameDoc =
        watchDocumentName === undefined ||
        watchDocumentName === null ||
        watchDocumentName === ""
          ? event.target.files[0].name
          : watchDocumentName;
      var requiredCheck =
        watchDocumentoObligatorio === undefined ||
        watchDocumentoObligatorio === null ||
        watchDocumentoObligatorio === ""
          ? false
          : watchDocumentoObligatorio;
      // console.log("listDocuments: ", listDocuments);
      var idDocument =
        listDocuments?.length > 0
          ? parseInt(listDocuments[listDocuments.length - 1].Id) + 1
          : listDocuments?.length + 1;

      let addItem = {
        Id: idDocument,
        DocResponseId: idDocument,
        DocumentConfiguration: {
          FileName: nameDoc,
        },
        Name: nameDoc,
        Required: requiredCheck,
      };

      const newListDocuments = [...listDocuments, addItem];
      setListDocuments(newListDocuments);
      setValue("documentName", "");
      setValue("documentoObligatorio", undefined);
    }
  };

  let handleAddDocument = () => {
    if (
      watchDocumentName === undefined ||
      watchDocumentName === null ||
      watchDocumentName === ""
    ) {
      return;
    }
    var nameDoc =
      watchDocumentName === undefined ||
      watchDocumentName === null ||
      watchDocumentName === ""
        ? "Documento Default"
        : watchDocumentName;
    var idDocument =
      listDocuments.length > 0
        ? parseInt(listDocuments[listDocuments.length - 1].Id) + 1
        : listDocuments?.length + 1;
    var requiredCheck =
      watchDocumentoObligatorio === undefined ||
      watchDocumentoObligatorio === null ||
      watchDocumentoObligatorio === ""
        ? false
        : watchDocumentoObligatorio;

    let addItem = {
      Id: idDocument,
      DocResponseId: idDocument,
      DocumentConfiguration: {
        FileName: nameDoc,
      },
      DocumentName: nameDoc,
      Required: requiredCheck,
    };

    const existeObjeto = listDocuments.some(
      (objeto) => objeto.DocumentName === addItem.DocumentName
    );

    if (existeObjeto) {
      Alert({
        icon: "error",
        title: "Error",
        text: "Ya existe un documento con ese nombre",
      });
    } else {
      const newListDocuments = [...listDocuments, addItem];
      setListDocuments(newListDocuments);
      setValue("documentName", "");
      setValue("documentoObligatorio", true);
    }
  };

  let handleRemoveDocument = (e) => {
    console.log(listDocuments);
    console.log(e);
    var lista = listDocuments.filter(
      (item) => item.DocumentName !== e.DocumentName
    );
    console.log(lista);
    setListDocuments(lista);
  };

  const agregarStageParticipante = async () => {
    const fileFlowService = FileFlowService.getInstance();
    await fileFlowService.registerStatusStage({
      File_id: folioIdSolicitud,
      Status: "Carga de documentos",
      Result: null,
    });
    dispatch(getFile({ FileId: folioIdSolicitud }));
    props.handleNextStep();
    // resetForm();
  };

  let handleNextSave = async (e) => {
    const fileService = FileService.getInstance();
    let responseParticipant;
    let responseIntegrante;
    let newData = listDocuments?.map((data) => {
      return {
        DocumentName: data.DocumentName,
        Required: data.Required,
      };
    });

    if (!watchParticipant.Id && !dataPre?.UploadPrerequisitesUserId) {
      responseParticipant = await fileService.createParticipant({
        Email: searchUser,
        UserAlias: watchParticipantName,
        FileIdReference:
          newFileState.editStack === true
            ? fileEdit?.item?.FileData?.Id
            : newFileState?.file?.Id,
      });
    }

    if (
      !watchSearchIntegrante?.Id &&
      watchRequiereProcAprobacion === true &&
      !dataPre?.PrerequisiteApprovalUserId
    ) {
      responseIntegrante = await fileService.createParticipant({
        Email: searchIntegrante,
        UserAlias: watchIntegranteName,
        FileIdReference: folioIdSolicitud,
      });
    }

    console.log(watchLimitDateReqPrevios);

    let request = {
      FileId: folioIdSolicitud,
      PrerequisitesConfiguration: {
        ActivePrerequisites: true,
        UploadPrerequisitesUserId: watchParticipant?.Id
          ? watchParticipant?.Id
          : !dataPre?.UploadPrerequisitesUserId
          ? responseParticipant?.Body?.UserId
          : dataPre?.UploadPrerequisitesUserId,
        PrerequisitesDocumentList: newData,
        UploadPrerequisitesDeadline: watchLimitDateReqPrevios
          ? watchLimitDateReqPrevios
          : null,
        ActivePrerequisiteApproval: watchRequiereProcAprobacion,
        PrerequisiteApprovalUserId:
          !watchSearchIntegrante?.Id &&
          watchRequiereProcAprobacion === true &&
          !dataPre?.PrerequisiteApprovalUserId
            ? responseIntegrante?.Body?.UserId
            : watchSearchIntegrante?.Id
            ? watchSearchIntegrante?.Id
            : dataPre?.UploadPrerequisitesUserId,
      },
    };

    dispatch(setPrerrequisites(request));
    dispatch(RegisterPrerequisites(request));
    // setChangeDatos(true);
    agregarStageParticipante();
  };

  const handleValidateDate = (typeDate) => {
    return new Date();
  };

  const cancelarProceso = async () => {
    let request = {
      FileId: folioIdSolicitud,
      PrerequisitesConfiguration: {
        ActivePrerequisites: false,
      },
    };

    const fileFlowService = FileFlowService.getInstance();
    await fileFlowService.registerStatusStage({
      File_id: folioIdSolicitud,
      Status: "Carga de documentos",
      Result: null,
    });
    localStorage.removeItem("ctrlReqPrevios");
    dispatch(RegisterPrerequisites(request));
    props.omitirPrevios();
    setAlertCancel(false);
  };

  console.log(folioIdSolicitud);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
      <RootStyle>
        <Accordion disableGutters defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            sx={{
              mt: 1,
              p: 0,
              "& .MuiAccordionSummary-content": {
                m: 0,
              },
            }}
          >
            <Typography variant="titulo">
              Participante responsable de cargar los requisitos previos
              <Tip
                title="Este usuario será el encargado de cargar los documentos solicitados por
                  el usuario administrador."
                placement="top"
                arrow
                enterTouchDelay={0}
              >
                <InfoOutlinedIcon sx={{ ml: 1, fontSize: 23 }} />
              </Tip>
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingLeft: 0, paddingTop: 16 }}>
            <input
              type="file"
              hidden
              ref={fileLoader}
              accept=".pdf/*,docx/*,image/*"
              onChange={getFileLoaded}
            />

            <Box
              sx={{
                mb: 3,
                display: "flex",
                alignItems: "center",
                gap: 0,
                flexWrap: { xs: "wrap" /* , sm: "nowrap" */ },
              }}
            >
              <Typography
                variant="body2"
                sx={{ mb: 1, display: "flex", alignItems: "center" }}
              >
                Ingresa el correo, nombre y fecha de vigencia del usuario
                responsable de subir los requisitos previos.
              </Typography>
              <InputField
                inputType="autocomplete"
                control={control}
                defaultValue=""
                name="searchParticipant"
                label="Correo electrónico"
                iconSpam={true}
                placeholder="Escribe el correo electrónico"
                error={!!formState.errors.searchParticipant}
                loadingParticipant={loadingParticipant}
                optionsList={results}
                validations={{
                  validate: (v) => v && emailPattern.test(v?.name),
                }}
                onInputChange={(e, v) => {
                  setsearchUser(v);
                }}
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={(e, r) => {
                  if (r === "removeOption") {
                    resetField("searchParticipant");
                  }
                  setOpen(false);
                }}
              />
              {/* {!loadingParticipant &&
                results.length === 0 &&
                watchParticipant &&
                !formState.errors?.searchParticipant && (
                  <Typography
                    variant="caption"
                    color="#C20E30"
                    component={"p"}
                    sx={{ display: "block", mt: 1 }}
                  >
                    No se encontró un registro para este participante, escriba
                    la información solicitada para enviarle una invitación.
                  </Typography>
                )} */}
              {formState.errors.searchParticipant?.type === "validate" && (
                <Typography
                  variant="caption"
                  color="#C20E30"
                  component={"p"}
                  sx={{ display: "block", mt: 1 }}
                >
                  E-mail no válido
                </Typography>
              )}
              {watchParticipant && !formState.errors.searchParticipant && (
                <InputField
                  disabled={
                    watchParticipant.Id || formState.errors.searchParticipant
                  }
                  inputType="text"
                  control={control}
                  name="participantName"
                  label={
                    watchParticipant.Id ? "Nombre Completo" : " Nombre o Alias"
                  }
                  error={!!formState.errors["participantName"]}
                />
              )}
              <Box width={'100%'}>
              <InputField
                inputType="date"
                control={control}
                iconSpam={true}
                label="Fecha límite"
                name="limitDateReqPrevios"
                validations={{ required: true }}
                error={!!formState.errors.limitDateSignParticipant}
                minDate={handleValidateDate()}
                defaultValue={""}
                sxInput={{ maxWidth: 500 }}
                // maxDate={handleValidateDateMax("sign")}
              />
              </Box>
              <Box width={'100%'}>
                <InputField
                  inputType="check"
                  control={control}
                  name="requiereProcAprobacion"
                  label="Requiere proceso de aprobación"
                  tooltipText="Los documentos que serán cargados por el participante tendrán la opción de ser revisados y aprobados por el usuario aprobador."
                  defaultValue={false}
                />
              </Box>
            </Box>

            {watchRequiereProcAprobacion ? (
              <>
                <Typography variant="titulo" sx={{ mb: 1, mt: 3 }}>
                  Integrante aprobador
                  <Tip
                    title="Este usuario será el encargado de aprobar los requisitos previos."
                    placement="top"
                    arrow
                    enterTouchDelay={0}
                  >
                    <InfoOutlinedIcon sx={{ ml: 1, fontSize: 18 }} />
                  </Tip>
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ mb: 1, display: "flex", alignItems: "center" }}
                >
                  Ingresa el correo del usuario responsable de la revisión de
                  los requisitos previos.
                </Typography>
                <Box
                  sx={{
                    mb: 3,
                    display: "flex",
                    alignItems: "center",
                    gap: 0,
                    flexWrap: { xs: "wrap" /* , sm: "nowrap" */ },
                  }}
                >
                  <InputField
                    inputType="autocomplete"
                    control={control}
                    defaultValue=""
                    name="searchIntegrante"
                    label="Correo electrónico"
                    iconSpam={true}
                    placeholder="Escribe el correo electrónico"
                    error={!!formState.errors.searchIntegrante}
                    loadingIntegrante={loadingIntegrante}
                    optionsList={resultsIntegrante}
                    validations={{
                      validate: (v) => v && emailPattern.test(v?.name),
                    }}
                    onInputChange={(e, v) => {
                      setsearchIntegrante(v);
                    }}
                    open={openIntegrante}
                    onOpen={() => {
                      setOpenIntegrante(true);
                    }}
                    onClose={(e, r) => {
                      if (r === "removeOption") {
                        resetField("searchIntegrante");
                      }
                      setOpenIntegrante(false);
                    }}
                  />
                  {formState.errors.searchIntegrante?.type === "validate" && (
                    <Typography
                      variant="caption"
                      color="#C20E30"
                      component={"p"}
                      sx={{ display: "block", mt: 1 }}
                    >
                      E-mail no válido
                    </Typography>
                  )}
                  {watchSearchIntegrante &&
                    !formState.errors.searchIntegrante && (
                      <InputField
                        disabled={
                          watchSearchIntegrante.Id ||
                          formState.errors.searchIntegrante
                        }
                        inputType="text"
                        control={control}
                        name="integranteName"
                        label={
                          watchSearchIntegrante.Id
                            ? "Nombre Completo"
                            : " Nombre o Alias"
                        }
                        error={!!formState.errors["integranteName"]}
                      />
                    )}
                </Box>
              </>
            ) : null}
            <Typography variant="titulo" sx={{ mb: 1, mt: 3 }}>
              Documentos a subir
            </Typography>
            <Typography
              variant="body2"
              sx={{ mb: 1, display: "flex", alignItems: "center" }}
            >
              Ingresa el nombre de los documentos a solicitar al responsable de
              carga
            </Typography>
            <InputField
              inputType="text"
              control={control}
              label="Nombre de documento"
              name="documentName"
              placeholder="Ingresa el nombre del documento"
              // validations={{ required: true }}
              error={!!formState.errors["documentName"]}
            />
            <InputField
              inputType="check"
              control={control}
              name="documentoObligatorio"
              label="Documento obligatorio"
              tooltipText=""
              defaultValue={false}
            />
            {Object.keys(formState.errors).length > 0 && (
              <Typography
                variant="caption"
                color="primary"
                sx={{ display: "block", mt: 1 }}
              >
                Campos requeridos*
              </Typography>
            )}
            <LoadingButton
              variant="contained"
              disabled={
                watchDocumentName?.length === 0 ||
                watchDocumentName === undefined
              }
              fullWidth={xsScreen}
              sx={{
                mt: 4,
                mb: 10,
                height: 45,
                marginRight: "auto",
                display: "block",
                maxWidth: 380,
              }}
              onClick={handleSubmit(handleAddDocument)}
            >
              Añadir documento
            </LoadingButton>

            {listDocuments?.length > 0 && (
              <Box sx={(theme) => ({ boxShadow: theme.shadows[9], mt: 2 })}>
                {listDocuments?.map((doc, i) => (
                  <DocumentButton
                    listDocs={false}
                    key={doc.Id}
                    doc={doc}
                    index={i + 1}
                    HandleRemoveList={handleRemoveDocument}
                  />
                ))}
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
        <Grid container spacing={2} justifyContent="center" sx={{ mt: 4 }}>
          <Divider />
          <Grid item xs={12} sm={4}>
            <Button
              fullWidth
              color="secondary"
              variant="outlined"
              sx={{ height: 45, px: 10 }}
              onClick={() => {
                dispatch(getFile({ FileId: folioIdSolicitud }));
                props.handleBackStep();
              }}
            >
              Anterior
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <LoadingButton
              fullWidth
              variant="contained"
              sx={{ height: 45, px: 10 }}
              onClick={() => setAlertCancel(true)}
            >
              Omitir
            </LoadingButton>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              fullWidth
              variant="contained"
              sx={{ height: 45, px: 10 }}
              onClick={handleNextSave}
            >
              Siguiente
            </Button>
          </Grid>
        </Grid>
      </RootStyle>

      <CustomModalAlert
        Open={alertCancel}
        Title="¿Está seguro que deseas omitir este paso?"
        SubTitle="Si omites este paso, ya no podrás ingresar documentos previos a tu proceso de revisión y/o firmado"
        LeftButtonText="Aceptar"
        LeftButtonAction={() => cancelarProceso()}
        RightButtonText="Cancelar"
        RightButtonAction={() => {
          setAlertCancel(false);
        }}
      />
    </LocalizationProvider>
  );
};
