import { authenticationConstants } from "../constants";
import { AuthService } from "../../services/AuthService";
import { Alert } from "../../components/Alert";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { renderToString } from "react-dom/server";

export const authentication = (authData) => {
  return async (dispatch) => {
    dispatch(request());
    try {
      const authService = AuthService.getInstance();
      const authenticationData = await authService.authenticationEncripted(
        authData
      );
      dispatch(success(authenticationData.Body));
    } catch (error) {
      dispatch(failure(error.message));
      Alert({
        icon: "error",
        title: "Datos incorrectos",
        text: 'El usuario o contraseña son incorrectos. Si no tienes una cuenta en DocSolutions, puedes registrarte ahora.',
      }, () => { window.grecaptcha.reset() });
    }
  };

  function request() {
    //Ingresar la petición hacia las API's (Aquí mostrar gif loader)
    return { type: authenticationConstants.AUTHENTICATION_REQUEST }; // => carpeta constants => AuthConstants.js
  }

  function success(authenticationData) {
    //Respuesta correcta de petición
    return {
      type: authenticationConstants.AUTHENTICATION_SUCCESS,
      payload: authenticationData,
    }; // => carpeta constants => AuthConstants.js
  }

  function failure(error) {
    //Respuesta errone ade petición (Mostrar modal de cortesía)
    return { type: authenticationConstants.AUTHENTICATION_FAILURE, error }; // => carpeta constants => AuthConstants.js
  };

};


export const noAuthentication = (authData) => {
  return async (dispatch) => {
    dispatch(request());
    try {
      dispatch(success(authData));
    } catch (error) {
      dispatch(failure(error.message));
      Alert({
        icon: "error",
        title: "Datos incorrectos",
        text: 'El usuario o contraseña son incorrectos. Si no tienes una cuenta en DocSolutions, puedes registrarte ahora.',
      });
    }
  };
  function request() {
    return { type: authenticationConstants.AUTHENTICATION_REQUEST };
  }
  function success(authenticationData) {
    return {
      type: authenticationConstants.AUTHENTICATION_SUCCESS,
      payload: authenticationData,
    };
  }
  function failure(error) {
    return { type: authenticationConstants.AUTHENTICATION_FAILURE, error };
  };

};

export const resetPassword = (newPass) => {
  console.log(newPass);

  return async (dispatch) => {
    dispatch(request());
    try {
      const authService = AuthService.getInstance();
      const resetPass = await authService.resetPassword(newPass);
      console.log(resetPass);
      dispatch(success(resetPass));
      dispatch(user_pass(newPass));
      Alert({
        icon: "success",
        title: "Código enviado"
      });
    } catch (error) {
      console.log(error)
      dispatch(failure(error.message));
      Alert({
        icon: "error",
        title: "Error",
        text: "Hubo un error. " + error.message,
      });
    }
  };

  function request() {
    return { type: authenticationConstants.AUTHENTICATION_REQUEST };
  }

  function success(resetData) {
    return {
      type: authenticationConstants.RESSET_PASSWORD_SUCCES,
      payload: resetData,
    };
  }

  function user_pass(user) {
    return {
      type: authenticationConstants.RESSET_PASSWORD_SUCCES,
      payload: user,
    };
  }

  function failure(error) {
    return { type: authenticationConstants.AUTHENTICATION_FAILURE, error };
  };

};

export const validatePIN = (pin) => {
  return async (dispatch) => {
    dispatch(request());
    try {
      const authService = AuthService.getInstance();
      const validate = await authService.validatePIN(pin);
      dispatch(success(validate));
      sessionStorage.removeItem('userPass');
    } catch (error) {
      dispatch(failure(error.message));
    }
  };

  function request() {
    return { type: authenticationConstants.AUTHENTICATION_REQUEST };
  }

  function success(resetData) {
    return {
      type: authenticationConstants.VALIDATE_PIN,
      payload: resetData,
    };
  }

  function failure(error) {
    return { type: authenticationConstants.AUTHENTICATION_FAILURE, error };
  };
};
